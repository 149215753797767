import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useAddNotificationMutation } from "../../services/manage-notification";
import { showError, showToast } from "../../constants/toast";
import { useLazyGetUsersQuery } from "../../services/user";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddNotification = () => {
  const selectRef = useRef<any>(null);
  const [checked, setChecked] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [names, setNames] = useState<any>([]);
  const navigate = useNavigate();
  const [getUsers] = useLazyGetUsersQuery();
  const getUsersList = async () => {
    // setIsLoading(true);
    try {
      const response = await getUsers({
        wpagination: true,
        // page: 1,
        // limit: 10,
        // query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setNames(response?.data || []);
      } else {
        setNames([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    // setIsLoading(false);
  };
  const [receiver, setReceiver] = useState<string>("All");
  const [title, setTitle] = useState<any>("");
  const [message, setMessage] = useState<any>("");
  const [allSelected, setAllSelected] = useState(false);
  const [addNotification] = useAddNotificationMutation();
  const [userNames, setUserNames] = useState<any>([]);
  console.log(userNames);

  const handleAddNotification = async () => {
    try {
      const body = {
        title: title,
        message: message,
        userArray: selectAll ? [] : userNames,
      };
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await addNotification(encryptedBody).unwrap();
      if (response.statusCode == 200) {
        showToast("Notification added successfully");
        navigate("/manage-notifications");
      }
    } catch (error: any) {
      showError(error.data.message);
    }
  };

  const handleReceiverChange = (event: SelectChangeEvent<typeof userNames>) => {
    const {
      target: { value },
    } = event;
    setUserNames(
      // Ensure the value is not null or undefined
      typeof value === "string" ? [value] : value
    );
  };
  useEffect(() => {
    getUsersList();
  }, []);
  return (
    <>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Add Notification</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-notifications");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setError(true);
              if (title == "") {
                showError("Title is required");
                return;
              }
              if (message == "") {
                showError("Message is required");
                return;
              }
              if (!selectAll && userNames.length == 0) {
                showError("Please select recievers");
                return;
              }
              handleAddNotification();
            }}
          >
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Notification Title
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="title"
                    value={title}
                    onChange={(e) => {
                      if (e.target.value.length <= 50) {
                        setTitle(e.target.value);
                      }
                      if (e.target.value.length > 50) {
                        showError("Title cant exceeed 50 characters");
                      }
                    }}
                    variant="outlined"
                    fullWidth
                    placeholder="Title"
                  />
                  {error && title == "" ? (
                    <h6 className="err_msg">Title is required</h6>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Notification Message
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    value={message}
                    onChange={(e) => {
                      if (e.target.value.length <= 100) {
                        setMessage(e.target.value);
                      }
                      if (e.target.value.length > 100) {
                        showError("Message cant exceeed 100 characters");
                      }
                    }}
                    name="message"
                    variant="outlined"
                    fullWidth
                    placeholder="Message"
                  ></TextField>
                  {error && message == "" ? (
                    <h6 className="err_msg">Message is required</h6>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Select Receiver
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      // onScroll={handleScroll}
                      fullWidth
                      className="user_lst"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={userNames}
                      sx={{ overflowY: "auto" }}
                      multiple
                      // multiple
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      MenuProps={{
                        sx: { maxHeight: "300px" },
                        PaperProps: {
                          ref: selectRef,
                          //  onScroll:handleScroll
                        },
                      }}
                      disabled={selectAll}
                      displayEmpty
                      onChange={handleReceiverChange}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <span>Select</span>;
                        }
                        const selectedUserNames = selected.map(
                          (userId: any) => {
                            const userFound = names.find(
                              (user: any) => user._id === userId
                            );
                            return userFound ? `${userFound.fullName}` : "";
                          }
                        );
                        return selectedUserNames.join(", ");
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>

                      {names?.map((item: any, index: any) => (
                        <MenuItem value={item?._id}>{item?.fullName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    id="all"
                    checked={selectAll}
                    onChange={() => {
                      setSelectAll(!selectAll);
                      setUserNames([]);
                    }}
                  />{" "}
                  <label htmlFor="all">Select All</label>
                </Typography>
                <Box></Box>
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </>
  );
};

export default AddNotification;
