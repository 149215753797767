import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import HTMLReactParser from "html-react-parser";
const workoutVideo = ({ details }: any) => {
  const rating = 4.7; // Example rating value

  // Function to render star icons based on the rating
  // const renderRatingStars = () => {
  //   const stars = [];
  //   const fullStars = Math.floor(rating);
  //   const hasHalfStar = rating % 1 !== 0;

  //   // Add full stars
  //   for (let i = 0; i < fullStars; i++) {
  //     stars.push(<StarIcon key={i} />);
  //   }

  //   // Add half star if necessary
  //   if (hasHalfStar) {
  //     stars.push(<StarOutlineIcon key="half" />);
  //   }

  //   // Add remaining empty stars to make a total of 5
  //   const remainingStars = 5 - Math.ceil(rating);
  //   for (let i = 0; i < remainingStars; i++) {
  //     stars.push(<StarOutlineIcon key={`empty-${i}`} />);
  //   }

  //   return stars;
  // };

  return (
    <Card className="cards">
      <CardContent sx={{ p: 1 }}>
        <Grid container spacing={2} className="view_box">
          <Grid item lg={2} md={2} sm={6} xs={12}>
            <figure className="profile_img">
              <img
                src={
                  details?.image
                    ? details?.image
                    : "/static/images/workoutvideo-placeholder.png"
                }
                alt=""
              />
            </figure>
          </Grid>
          <Grid item xs={10} className="view_box_list">
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Title</Typography>
                  <Typography component="p">{details?.exerciseName}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Video category</Typography>
                  <Typography component="p">
                    {details?.workoutCategory?.name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Duration</Typography>
                  <Typography component="p">{details?.duration}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5"> Description</Typography>
                  <Typography component="p">
                    {details?.description.trim() == "<p></p>" ? (
                      <Typography component="p">No description</Typography>
                    ) : (
                      HTMLReactParser(details?.description || "")
                    )}
                  </Typography>
                </Box>
              </Grid>
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">View</Typography>
                  <Typography component="p">350k</Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Hits</Typography>
                  <Typography component="p">10k+</Typography>
                </Box>
              </Grid>
               */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Rating</Typography>
                 
                  <Box display="flex">
                    {renderRatingStars()}
                  </Box>
                </Box>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default workoutVideo;
