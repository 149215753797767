import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add, Visibility } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { showError, showToast } from "../../constants/toast";
import useAuth from "../../hooks/useAuth";
import {
  useDeleteBiohackingByIdMutation,
  useEditBiohackingByIdMutation,
  useLazyGetBiohackingQuery,
} from "../../services/biohacking";
import { UserResponse } from "../../types/User";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { BiohackingResponse } from "../../types/biohacking";
import { isValidInput } from "../../utils/validations";
import { handleDelete } from "../../utils/commonFunctions";
import WarnModal from "../../components/modals/WarnModal";
import Loader from "../../helpers/Loader";
import { Pagination } from "../../components";
const MnnageBioHacking = () => {
  function createData(Name: string, Email: string, Phone: string) {
    return { Name, Email, Phone };
  }
  const rows = [createData("Sub Admin", "subadmin@gmail.com", "+971056734321")];
  const [isLoading, setIsLoading] = useState(false);
  const user = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [biohackingData, setBiohackingData] = useState<any>([]);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const [getUsers] = useLazyGetBiohackingQuery();
  const [updateBiohackingStatus]: any = useEditBiohackingByIdMutation();
  const [deleteById] = useDeleteBiohackingByIdMutation();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getBiohackingList = async () => {
    setIsLoading(true);
    try {
      const response = await getUsers({
        page: page,
        // limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setBiohackingData(response?.data?.biohacking || []);
        setTotalCount(response?.data?.count);
      } else {
        setBiohackingData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
  };

  const handleStatusChange = async (index: number) => {
    try {
      const body = {
        isBlocked: !biohackingData[index]?.isBlocked,
      };
      console.log(body);
      const encryptedData = generateEncryptedKeyBody(body);
      const response = await updateBiohackingStatus({
        id: `${biohackingData[index]._id}`,
        body: encryptedData,
      }).unwrap();
      showToast("Status updated successfully");
      setBiohackingData((prevData: any) => {
        let temp = [...prevData];
        temp[index] = { ...temp[index], isBlocked: !temp[index].isBlocked };
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
      //      console.log(error);
    }
  };
  useEffect(() => {
    //   console.log("TESTING")
    getBiohackingList();
  }, [debouncedSearchTerm, page]);
  console.log(biohackingData);
  // useEffect(() => {
  //   if (userData && userData?.permission?.length) {
  //     checkPermission();
  //   }
  // }, [userData]);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <div className="main_loyout">
      <Loader isLoad={isLoading} />

      <div className="dashboard">
        <h1 className="mn_hdng"> Manage Bio-Hacking</h1>
      </div>
      <Card className="cards">
        <Box className="cards_header">
          <SearchBar
            searchTerm={searchTerm}
            setDebouncedSearchTerm={setDebouncedSearchTerm}
            value={searchTerm}
            onCross={() => setSearchTerm("")}
            onChange={(val: any) => {
              if (isValidInput(val.target.value)) {
                setSearchTerm(val.target.value);
              }
            }}
          />
          <Box className="cards_header_right">
            {user?.role == 1 || user?.permissions?.[3]?.isEdit ? (
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-bio-hacking/add")}
              >
                {<Add />}Add Bio-Hacking
              </Button>
            ) : null}
          </Box>
        </Box>
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Image</TableCell>

                {/* <TableCell>Title</TableCell> */}
                <TableCell sx={{ textAlign: "center" }}>Method</TableCell>
                {/* <TableCell>Description</TableCell> */}

                <TableCell sx={{ textAlign: "center" }}>Status</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {biohackingData.length ? (
                biohackingData.map((row: BiohackingResponse, i: number) => (
                  <TableRow>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <figure className="user_img">
                        <img
                          src={
                            row.image
                              ? row.image
                              : "/static/images/workoutcategory-placeholder.png"
                          }
                          alt=""
                        />
                      </figure>
                    </TableCell>

                    <TableCell sx={{ textAlign: "center" }}>
                      {row.method}
                    </TableCell>
                    {/* <TableCell>Body Scan</TableCell> */}
                    {/* <TableCell> {row.description}</TableCell> */}

                    <TableCell align="center">
                      <Switch
                        {...label}
                        size="small"
                        disabled={
                          !(
                            user?.role == 1 ||
                            (user?.role == 2 && user?.permissions?.[3].isEdit)
                          )
                        }
                        checked={!row.isBlocked}
                        onChange={() => handleStatusChange(i)}
                      />
                    </TableCell>

                    <TableCell sx={{ textAlign: "center" }}>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() =>
                            navigate("/manage-bio-hacking/details/" + row._id)
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                        {user?.role == 1 || user?.permissions?.[3]?.isEdit ? (
                          <IconButton
                            onClick={() =>
                              navigate("/manage-bio-hacking/edit/" + row._id)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                        ) : null}
                        {user?.role == 1 || user?.permissions?.[3]?.isDelete ? (
                          <IconButton
                            onClick={() => {
                              setSelectedId(row._id);
                              setOpen(true);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ) : null}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                    No Biohacking Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <WarnModal
          setOpen={setOpen}
          open={open}
          name={"biohacking"}
          handleDelete={() =>
            handleDelete(deleteById, selectedId, getBiohackingList)
          }
        />
      </Card>
      <Pagination
        module={biohackingData}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </div>
  );
};

export default MnnageBioHacking;
