import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { showError, showToast } from "../../constants/toast";
import useAuth from "../../hooks/useAuth";
import {
  useLazyGetUsersQuery,
  useEditUserByIdMutation,
  useDeleteUserByIdMutation,
} from "../../services/user";
import { UserResponse } from "../../types/User";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import {
  useDeleteSubAdminByIdMutation,
  useEditSubAdminByIdMutation,
  useLazyGetSubAdminsQuery,
} from "../../services/subadmin";
import users from "../users";
import { isValidInput } from "../../utils/validations";
import { Pagination } from "../../components";
import { handleDelete } from "../../utils/commonFunctions";
import WarnModal from "../../components/modals/WarnModal";
import Loader from "../../helpers/Loader";
import { Add } from "@mui/icons-material";
const ManageSubAdmin = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [subAdmins, setSubAdmins] = useState<any>([]);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const [getSubAdmins] = useLazyGetSubAdminsQuery();
  const [updateAdminStatus]: any = useEditSubAdminByIdMutation();
  const [deleteById] = useDeleteSubAdminByIdMutation();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getSubAdminsList = async () => {
    setIsLoading(true);
    try {
      const response = await getSubAdmins({
        page: page,
        // limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        console.log("SUB", response);
        setSubAdmins(response?.data?.subAdmin || []);
        setTotalCount(response?.data?.count);
      } else {
        setSubAdmins([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
  };

  const handleStatusChange = async (index: number) => {
    try {
      const body = {
        isBlocked: !subAdmins[index]?.isBlocked,
      };
      console.log(body);
      const encryptedData = generateEncryptedKeyBody(body);
      const response = await updateAdminStatus({
        id: `${subAdmins[index]._id}`,
        body: encryptedData,
      }).unwrap();
      showToast(response?.message || "User Updated Successfully");
      setSubAdmins((prevData: any) => {
        let temp = [...prevData];
        temp[index] = { ...temp[index], isBlocked: !temp[index].isBlocked };
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
      //      console.log(error);
    }
  };
  useEffect(() => {
    //   console.log("TESTING")
    getSubAdminsList();
  }, [debouncedSearchTerm, page]);
  console.log(subAdmins);
  // useEffect(() => {
  //   if (userData && userData?.permission?.length) {
  //     checkPermission();
  //   }
  // }, [userData]);

  function createData(Name: string, Email: string, Phone: string) {
    return { Name, Email, Phone };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Sub Admin", "subadmin@gmail.com", "+971056734321")];

  return (
    <div className="main_loyout">
      <Loader isLoad={isLoading} />
      <div className="dashboard">
        <h1 className="mn_hdng"> Manage Sub-Admin</h1>
      </div>
      <Card className="cards">
        <Box className="cards_header">
          <SearchBar
            searchTerm={searchTerm}
            setDebouncedSearchTerm={setDebouncedSearchTerm}
            value={searchTerm}
            onCross={() => setSearchTerm("")}
            onChange={(val: any) => {
              if (isValidInput(val.target.value)) {
                setSearchTerm(val.target.value);
              }
            }}
          />
          <Box className="cards_header_right">
            <Button
              className="btn btn_primary"
              onClick={() => navigate("/manage-subadmin/add")}
            >
              {<Add />} Add Sub-Admin
            </Button>
          </Box>
        </Box>
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subAdmins.length ? (
                subAdmins.map((row: any, i: number) => (
                  <TableRow>
                    <TableCell align="center">
                      {(page - 1) * 10 + i + 1}
                    </TableCell>
                    <TableCell>{row.fullName}</TableCell>
                    <TableCell>{row?.subadminRole?.role}</TableCell>

                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>
                      <Switch
                        {...label}
                        checked={!row.isBlocked}
                        size="small"
                        onChange={() => handleStatusChange(i)}
                      />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() =>
                            navigate("/manage-subadmin/edit/" + row._id)
                          }
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setSelectedId(row._id);
                            setOpen(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                    No Sub-Admin Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Pagination
        module={subAdmins}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        setOpen={setOpen}
        open={open}
        name={"sub-admin"}
        handleDelete={() =>
          handleDelete(deleteById, selectedId, getSubAdminsList)
        }
      />
    </div>
  );
};

export default ManageSubAdmin;
