import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
import { SubscriptionHistoryResopnse, SubscriptionResponse } from "../types/subscription";
type CommonResponseType = {
    statusCode: number;
    message: string;
};
type GetAllSubscriptionResponse = {
    pages: number
    message: string
    subscription: SubscriptionHistoryResopnse[];
    count: number;
    totalEarning: number
};

type GetTokenParams = {
    size?: number;
    page?: number;
    query?: string;
};
export const userApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getSubscriptionHistory: builder.query<
            CommonResponseType & { data: GetAllSubscriptionResponse },
            GetTokenParams
        >({
            // query:({page,limit,query})=>{
            query: ({ page, query, size }) => {
                let url = END_POINTS.subscriptionHistory;
                const queryParams = [];
                if (page) {
                    queryParams.push(`page=${page}`);
                }
                if (size) {
                    queryParams.push(`size=${size}`);
                }
                if (query) {
                    queryParams.push(`search=${query}`);
                }
                // if (limit) {
                //   queryParams.push(`limit=${limit}`);
                // }
                if (queryParams.length > 0) {
                    url += `?${queryParams.join("&")}`;
                }
                return {
                    url: url,
                    method: "GET",
                };
            },
        }),
    }),
});

export const {
    useLazyGetSubscriptionHistoryQuery,
} = userApi;
