import React, { ChangeEvent, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import Recipe from "./recipe";

const AddRecipeDetailed = ({ type, snacks, setSnacks }: any) => {
  const navigate = useNavigate();

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0].type.includes("image")) {
      setFileName(files[0].name);
      setImage(URL.createObjectURL(files[0]));
    } else {
      setAlertType(0);
      setShowAlert(true);
      setAlertMessage("This field only accepts images.");
    }
  };

  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0].type.includes("video")) {
      setFileName(files[0].name);
      setVideo(URL.createObjectURL(files[0]));
    } else {
      setAlertType(0);
      setShowAlert(true);
      setAlertMessage("This field only accepts videos.");
    }
  };

  const handleMediaCancel = () => {
    setImage("");
    setVideo("");
    setFileName("");
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  return (
    <div className="main_loyout">
      <div className="dashboard"></div>
      <Card className="cards"></Card>
    </div>
  );
};

export default AddRecipeDetailed;
