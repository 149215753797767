import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";

const ActivityHistory = ({ userData }: any) => {
  return (
    <Card className="cards">
      <CardContent sx={{ p: 1 }}>
        <Grid container spacing={2} className="view_box">
          <Grid item xs={10} className="view_box_list">
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    Nutrition Survey & Fitness Survey
                  </Typography>
                  <Typography component="p">-</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Health Problems</Typography>
                  <Typography component="p">
                    {userData.healthProblem ? userData.healthProblem : "-"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Other Health Problems</Typography>
                  <Typography component="p">
                    {userData.healthProblemOther
                      ? userData.healthProblemOther
                      : "-"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Height(in cm)</Typography>
                  <Typography component="p">
                    {userData.heightCm ? userData.heightCm : "-"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Height(in feet)</Typography>
                  <Typography component="p">
                    {userData.heightFt ? userData.heightFt : "-"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Weight(in Kgs)</Typography>
                  <Typography component="p">
                    {userData.weightKg ? userData.weightKg : "-"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Weight(in Lbs)</Typography>
                  <Typography component="p">
                    {userData.weightLbs ? userData.weightLbs : "-"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Target weight(in Kgs)</Typography>
                  <Typography component="p">
                    {userData.targetKg ? userData.targetKg : "-"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Target weight(in Lbs)</Typography>
                  <Typography component="p">
                    {userData.targetLbs ? userData.targetLbs : "-"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Experience Level</Typography>
                  <Typography component="p">
                    {userData.expLevel ? userData.expLevel : "-"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Activity Level</Typography>
                  <Typography component="p">
                    {userData.activityLevel ? userData.activityLevel : "-"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Fitness Goals</Typography>
                  <Typography component="p">
                    {userData.fitnessGoal ? userData.fitnessGoal : "-"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Other</Typography>
                  <Typography component="p">
                    {userData.otherValue ? userData.otherValue : "-"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ActivityHistory;
