import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add, Visibility } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { showError, showToast } from "../../constants/toast";
import useAuth from "../../hooks/useAuth";
import {
  useLazyGetUsersQuery,
  useEditUserByIdMutation,
  useDeleteUserByIdMutation,
  useLazyGetUserCSVQuery,
} from "../../services/user";
import { UserResponse } from "../../types/User";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useLazyGetRecipeQuery } from "../../services/recipe";
import { RecipeResponse } from "../../types/recipe";
import { isValidInput } from "../../utils/validations";
import { Pagination } from "../../components";
import {
  useDeleteDietByIdMutation,
  useEditDietByIdMutation,
  useLazyGetDietsQuery,
} from "../../services/diet";
import { handleDelete } from "../../utils/commonFunctions";
import WarnModal from "../../components/modals/WarnModal";
import Loader from "../../helpers/Loader";
const ManageRecipes = () => {
  const user = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [diets, setDiets] = useState<any>([]);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const [updateDietStatus] = useEditDietByIdMutation();
  const [getDiets] = useLazyGetDietsQuery();
  const [deleteById] = useDeleteDietByIdMutation();
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getDietList = async () => {
    setIsLoading(true);
    try {
      const response = await getDiets({
        page: page,
        // limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setDiets(response?.data?.diet || []);
        setTotalCount(response?.data?.count);
      } else {
        setDiets([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
  };

  const handleStatusChange = async (index: number) => {
    try {
      const body = {
        isBlocked: !diets[index]?.isBlocked,
      };
      console.log(body);
      const encryptedData = generateEncryptedKeyBody(body);
      const response = await updateDietStatus({
        id: `${diets[index]._id}`,
        body: encryptedData,
      }).unwrap();
      showToast(response?.message || "User Updated Successfully");
      setDiets((prevData: any) => {
        let temp = [...prevData];
        temp[index] = { ...temp[index], isBlocked: !temp[index].isBlocked };
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
      //      console.log(error);
    }
  };
  useEffect(() => {
    //   console.log("TESTING")
    getDietList();
  }, [debouncedSearchTerm, page]);
  console.log(diets);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <div className="main_loyout">
      <Loader isLoad={isLoading} />

      <div className="dashboard">
        <h1 className="mn_hdng"> Manage Recipes</h1>
      </div>
      <Card className="cards">
        <Box className="cards_header">
          <SearchBar
            searchTerm={searchTerm}
            setDebouncedSearchTerm={setDebouncedSearchTerm}
            value={searchTerm}
            onCross={() => setSearchTerm("")}
            onChange={(val: any) => {
              if (isValidInput(val.target.value)) {
                setSearchTerm(val.target.value);
              }
            }}
          />
          <Box className="cards_header_right">
            {user?.role == 1 || user?.permissions?.[1]?.isEdit ? (
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-recipes/add")}
              >
                {<Add />} Add Recipes
              </Button>
            ) : null}
          </Box>
        </Box>
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Diet name</TableCell>
                <TableCell> Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {diets.length ? (
                diets.map((item: any, i: any) => (
                  <TableRow>
                    <TableCell align="center">
                      {(page - 1) * 10 + i + 1}
                    </TableCell>
                    <TableCell>
                      <figure className="user_img">
                        <img
                          src={
                            item.image
                              ? item.image
                              : "/static/images/recipe-placeholder.png"
                          }
                          alt=""
                        />
                      </figure>
                    </TableCell>
                    <TableCell>{item.name}</TableCell>

                    <TableCell align="center">
                      <Switch
                        {...label}
                        checked={!item.isBlocked}
                        onChange={() => handleStatusChange(i)}
                        size="small"
                        disabled={
                          !(
                            user?.role == 1 ||
                            (user?.role == 2 && user?.permissions?.[1].isEdit)
                          )
                        }
                      />
                    </TableCell>

                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() =>
                            navigate(`/manage-recipes/details/${item._id}`)
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                        {user?.role == 1 || user?.permissions?.[1]?.isEdit ? (
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-recipes/edit/${item._id}`)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                        ) : null}
                        {user?.role == 1 || user?.permissions?.[1]?.isDelete ? (
                          <IconButton
                            onClick={() => {
                              setOpen(true);
                              setSelectedId(item._id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ) : null}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                    No Recipe Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Pagination
        module={diets}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        setOpen={setOpen}
        open={open}
        name={"recipe"}
        handleDelete={() => handleDelete(deleteById, selectedId, getDietList)}
      />
    </div>
  );
};

export default ManageRecipes;
