import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import HTMLReactParser from "html-react-parser";
import MainContainer from "../../layout/MainContainer";
import { showError } from "../../constants/toast";
import { useLazyGetBiohackingsByIdQuery } from "../../services/biohacking";
import { BiohackingResponse } from "../../types/biohacking";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BioHackingDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const UserPlanFeatures = [
    { id: 1, name: "Nutritional Analysis" },
    { id: 2, name: "Genetic Testing" },
    { id: 3, name: "ABiomarker Monitoring" },
    { id: 4, name: "Supplementation" },
    { id: 5, name: "Functional Medicine" },
    { id: 6, name: "Microbiome Analysi" },
  ];
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [biohackingData, setBiohackingData] = useState<BiohackingResponse>();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [getDataById] = useLazyGetBiohackingsByIdQuery();
  const getDataByIdData = async () => {
    try {
      const response = await getDataById({ id: id }).unwrap();
      if (response.statusCode == 200) {
        setBiohackingData(response?.data);
      }
    } catch (error: any) {
      showError(error.data.message);
    }
  };

  useEffect(() => {
    getDataByIdData();
  }, []);
  return (
    <div className="main_loyout">
      <div className="dashboard">
        <h1 className="mn_hdng">View Bio-Hacking Details</h1>
        <Button
          className="btn btn_primary"
          onClick={() => {
            navigate("/manage-bio-hacking");
          }}
        >
          Back
        </Button>
      </div>
      <Card className="cards">
        <CardContent sx={{ p: 1 }}>
          <Grid container spacing={2} className="view_box">
            <Grid item xs={12} className="view_box_list">
              <Grid item lg={2} md={2} sm={6} xs={12}>
                <Typography component="h5">Image</Typography>
                <figure className="profile_img">
                  <img
                    src={
                      biohackingData?.image
                        ? biohackingData.image
                        : "/static/images/biohack.png"
                    }
                    alt=""
                  />
                </figure>
              </Grid>
              <Grid container spacing={3}>
                <Grid item mt={3} xs={12} className="view_box_list">
                  <Grid container spacing={3}>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                      <Box>
                        <Typography component="h5">Method Name</Typography>
                        <Typography component="p">
                          {biohackingData?.method}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5">Description</Typography>

                        {biohackingData?.description?.trim() == "<p></p>" ? (
                          <Typography component="p">No Description</Typography>
                        ) : (
                          <Typography
                            component="p"
                            sx={{ alignItems: "center" }}
                          >
                            {HTMLReactParser(biohackingData?.description || "")}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default BioHackingDetails;
