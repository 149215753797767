import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Parser from "html-react-parser";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import MainContainer from "../../layout/MainContainer";
import { SubscriptionResponse } from "../../types/subscription";
import { showError } from "../../constants/toast";
import { useLazyGetSubscriptionByIdQuery } from "../../services/subscription";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SubscriptionDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [subscriptionData, setSubscriptionData] =
    useState<SubscriptionResponse>();
  const [getSubscriptionById] = useLazyGetSubscriptionByIdQuery();

  const getData = async (id: string) => {
    try {
      const response = await getSubscriptionById({ id: id }).unwrap();
      if (response.statusCode == 200) {
        setSubscriptionData(response?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    if (id) getData(id);
  }, []);
  return (
    <div className="main_loyout">
      <div className="dashboard">
        <h1 className="mn_hdng">View Subscription Details</h1>
        <Button
          className="btn btn_primary"
          onClick={() => {
            navigate("/manage-subscription");
          }}
        >
          Back
        </Button>
      </div>
      <Card className="cards">
        <CardContent sx={{ p: 1 }}>
          <Grid container spacing={2} className="view_box">
            <Grid item xs={12} className="view_box_list">
              <Grid container spacing={3}>
                <Grid item mt={3} xs={12} className="view_box_list">
                  <Grid container spacing={3}>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                      <Box>
                        <Typography component="h5">Plan type</Typography>
                        <Typography component="p">
                          {subscriptionData?.planName || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                      <Box>
                        <Typography component="h5">Plan id</Typography>
                        <Typography component="p">
                          {subscriptionData?.subscriptionId || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                      <Box>
                        <Typography component="h5">Price</Typography>
                        <Typography component="p">
                          {" "}
                          ${subscriptionData?.price}
                          {subscriptionData?.planName == "Yearly"
                            ? "/year"
                            : "/month"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5">Features</Typography>
                        {subscriptionData?.feature_ar?.length != 0
                          ? subscriptionData?.feature_ar?.map(
                              (feature: any) => (
                                <Typography
                                  key={feature.id}
                                  component="p"
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <span
                                    style={{
                                      marginRight: "5px",
                                      width: "5px",
                                      height: "5px",
                                      borderRadius: "50%",
                                      backgroundColor: "black",
                                      display: "inline-block",
                                    }}
                                  ></span>
                                  {feature.name}
                                </Typography>
                              )
                            )
                          : "No features exist"}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5">Description</Typography>

                        <Typography component="p" sx={{ alignItems: "center" }}>
                          {subscriptionData?.description?.trim() == "<p></p>"
                            ? "No Description"
                            : subscriptionData?.description &&
                              Parser(subscriptionData?.description)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default SubscriptionDetails;
