import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
import { SubscriptionResponse } from "../types/subscription";
type CommonResponseType = {
  statusCode: number;
  message: string;
};
type GetAllSubscriptionResponse = {
  subscription: SubscriptionResponse[];
  count: number;
};

type GetTokenParams = {
  // limit?: number;
  page?: number;
  query?: string;
};

type EncryptedBody = {
  hash: string;
  sek: string;
} | null;
export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubscription: builder.query<
      CommonResponseType & { data: GetAllSubscriptionResponse },
      GetTokenParams
    >({
      // query:({page,limit,query})=>{
      query: ({ page, query }) => {
        let url = END_POINTS.subscription;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (query) {
          queryParams.push(`search=${query}`);
        }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    getSubscriptionById: builder.query<
      CommonResponseType & { data: SubscriptionResponse },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.subscription}/${id}`,
        method: "GET",
      }),
    }),
    editSubscriptionById: builder.mutation<
      CommonResponseType & { data: SubscriptionResponse },
      { id: string | undefined; body: EncryptedBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.subscription}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    addSubscription: builder.mutation<
      CommonResponseType & { data: SubscriptionResponse },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.subscription,
        method: "POST",
        body,
      }),
    }),

    deleteSubscriptionById: builder.mutation<
      CommonResponseType & { data: SubscriptionResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.subscription}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetSubscriptionQuery,
  useLazyGetSubscriptionByIdQuery,
  useAddSubscriptionMutation,
  useDeleteSubscriptionByIdMutation,
  useEditSubscriptionByIdMutation,
} = userApi;
