import { END_POINTS } from "../helpers";
import { FastingResponse } from "../types/fasting";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetAllFastingResponse = {
  fasting: FastingResponse[];
  count: number;
};

type GetTokenParams = {
  // limit?: number;
  page?: number;
  query?: string;
};

export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFasting: builder.query<
      CommonResponseType & { data: GetAllFastingResponse },
      GetTokenParams
    >({
      // query:({page,limit,query})=>{
      query: ({ page, query }) => {
        let url = END_POINTS.fasting;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (query) {
          queryParams.push(`search=${query}`);
        }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getFastingsById: builder.query<
      CommonResponseType & { data: FastingResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.fasting}/${id}`,
        method: "GET",
      }),
    }),

    changeFastingStatus: builder.query<
      CommonResponseType & { data: FastingResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.changeUserStatus}/${id}`,
        method: "GET",
      }),
    }),

    editFastingById: builder.mutation<
      CommonResponseType & { data: FastingResponse },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.fasting}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    deleteFastingById: builder.mutation<
      CommonResponseType & { data: FastingResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.fasting}/${id}`,
        method: "DELETE",
      }),
    }),

    addFasting: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.fasting,
        method: "POST",
        body,
      }),
    }),
  }),
});
export const {
  useLazyGetFastingQuery,
  useLazyGetFastingsByIdQuery,
  useEditFastingByIdMutation,
  useAddFastingMutation,
  useDeleteFastingByIdMutation,
} = userApi;
