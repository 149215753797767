import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Paper } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import LineChart from "../../components/LineChart";
import PaidIcon from "@mui/icons-material/Paid";
import { useNavigate } from "react-router-dom";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useLazyGetDashboardQuery } from "../../services/dashboard";
import { showError } from "../../constants/toast";
import {
  useLazyGetEarningGraphQuery,
  useLazyGetUserGraphQuery,
} from "../../services/analytics";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DashBoard = () => {
  const [getUsers]: any = useLazyGetUserGraphQuery();
  const [totalUserGraph, setTotalUserGraph] = useState<any>({
    labels: [],
    datasets: [
      {
        label: " Users",
        data: [],
        borderColor: "black",
        backgroundColor: "#ECFC73",
        color: "#FFFFF",
      },
    ],
  });
  const [totalEarningGraph, setTotalEarningGraph] = useState<any>({
    labels: [],
    datasets: [
      {
        label: "Earning",
        data: [],
        borderColor: "black",
        backgroundColor: "#ECFC73",
        color: "#FFFFF",
      },
    ],
  });
  const formatNumber = (value: number) => {
    if (value > 999999999999999) {
      return "NAN";
    } else if (value >= 1000000000000) {
      return (
        (value / 1000000000000).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }) + "T"
      );
    } else if (value >= 1000000000) {
      return (
        (value / 1000000000).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }) + "B"
      );
    } else if (value >= 1000000) {
      return (
        (value / 1000000).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }) + "M"
      );
    } else if (value >= 100000) {
      return (
        (value / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 }) +
        "K"
      );
    } else {
      return value.toLocaleString();
    }
  };
  const getUserData = async () => {
    try {
      const response = await getUsers("monthly").unwrap();
      if (response?.statusCode === 200) {
        console.log("users", response);
        const labels = [];
        const data = [];
        for (let key in response?.data?.totalUsers) {
          labels.push(key);
          data.push(response?.data?.totalUsers[key]);
        }
        setTotalUserGraph({
          labels: labels,
          datasets: [
            {
              label: " Users",
              data: data,
              borderColor: "black",
              backgroundColor: "#ECFC73",
              color: "#FFFFF",
            },
          ],
        });
      } else {
        setTotalUserGraph({
          labels: [],
          datasets: [
            {
              label: " Users",
              data: [],
              borderColor: "black",
              backgroundColor: "#ECFC73",
              color: "#FFFFF",
            },
          ],
        });
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const [getEarning] = useLazyGetEarningGraphQuery();
  const getEarningData = async () => {
    try {
      const response = await getEarning("monthly").unwrap();
      if (response?.statusCode === 200) {
        const labels = [];
        const data = [];
        for (let key in response?.data?.revenuGenerated) {
          labels.push(key);
          data.push(response?.data?.revenuGenerated[key]);
        }
        setTotalEarningGraph({
          labels: labels,
          datasets: [
            {
              label: "Earning",
              data: data,
              borderColor: "black",
              backgroundColor: "#ECFC73",
              color: "#FFFFF",
            },
          ],
        });
      } else {
        setTotalEarningGraph({
          labels: [],
          datasets: [
            {
              label: " Users",
              data: [],
              borderColor: "black",
              backgroundColor: "#ECFC73",
              color: "#FFFFF",
            },
          ],
        });
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const [getDashboard] = useLazyGetDashboardQuery();
  const [dashboardData, setDashboardData] = React.useState<any>([]);
  React.useEffect(() => {
    getUserData();
  }, []);
  React.useEffect(() => {
    getEarningData();
  }, []);

  const navigate = useNavigate();
  const getData = async () => {
    try {
      const response = await getDashboard({}).unwrap();
      if (response.statusCode == 200) {
        console.log("DASHBOARD API WORKING");
        setDashboardData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    getData();
  }, []);
  console.log(dashboardData);
  return (
    <div className="main_loyout">
      <div className="dashboard">
        <h1 className="mn_hdng">Dashboard</h1>
      </div>

      <Grid container spacing={2} className="dashGrid">
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-users")}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>Total Users</h3>
              <h4 className="mn_hdng">
                {dashboardData?.userCount ? dashboardData?.userCount : "0"}
              </h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-subscription")}
          >
            <SubscriptionsIcon className="svg_icn" />
            <div>
              <h3>Total Subscriptions</h3>
              <h4 className="mn_hdng">
                {dashboardData?.subscriptionCount
                  ? dashboardData?.subscriptionCount
                  : "0"}
              </h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item className="cards dashGrid_item">
            <PaidIcon className="svg_icn" />
            <div>
              <h3>Total Earnings</h3>
              <h4 className="mn_hdng">
                $
                {dashboardData?.totalEarning
                  ? formatNumber(dashboardData?.totalEarning)
                  : "0"}
              </h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item className="cards dashGrid_item">
            <FiberNewIcon className="svg_icn" />
            <div>
              <h3>New Users</h3>
              <h4 className="mn_hdng">
                {dashboardData?.newUsers ? dashboardData?.newUsers : "0"}
              </h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item className="cards dashGrid_item">
            <AddBoxIcon className="svg_icn" />
            <div>
              <h3>New Subscriptions</h3>
              <h4 className="mn_hdng">
                {dashboardData?.newSubscriptionCount
                  ? dashboardData?.newSubscriptionCount
                  : "0"}
              </h4>
            </div>
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">Total Users</h2>
            <LineChart data={totalUserGraph} />
          </Item>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">Total Earnings</h2>
            <LineChart data={totalEarningGraph} />
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashBoard;
