import {
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import HTMLReactParser from "html-react-parser";
const DetailsExercises = ({ exercise }: any) => {
  const rating = 4.7; // Example rating value
  console.log("999999999999999999999999", exercise);
  // Function to render star icons based on the rating
  const renderRatingStars = () => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    // Add full stars
    for (let i = 0; i < fullStars; i++) {
      stars.push(<StarIcon key={i} />);
    }

    // Add half star if necessary
    if (hasHalfStar) {
      stars.push(<StarOutlineIcon key="half" />);
    }

    // Add remaining empty stars to make a total of 5
    const remainingStars = 5 - Math.ceil(rating);
    for (let i = 0; i < remainingStars; i++) {
      stars.push(<StarOutlineIcon key={`empty-${i}`} />);
    }

    return stars;
  };

  return (
    <Card className="cards">
      <CardContent sx={{ p: 1 }}>
        <Grid container spacing={2} className="view_box">
          <Grid item lg={2} md={2} sm={6} xs={12}>
            <figure className="profile_img">
              <img
                src={
                  exercise?.image ? exercise?.image : "/static/images/exer.png"
                }
                alt=""
              />
            </figure>
          </Grid>
          <Grid item xs={10} className="view_box_list">
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Exercise Name</Typography>
                  <Typography component="p">{exercise.exerciseName}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Status</Typography>
                  <Typography component="p">
                    {exercise.isBlocked ? "InActive" : "Active"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Description</Typography>
                  <Typography component="p">
                    {exercise?.description == "<p></p>\n" ? (
                      <div>No Description</div>
                    ) : (
                      HTMLReactParser(exercise?.description || "No Description")
                    )}
                  </Typography>
                </Box>
              </Grid>

              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table
                  sx={{ minWidth: 650, maxWidth: 850 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Number of Reps</TableCell>
                      <TableCell>Weight</TableCell>
                      <TableCell>Rest Time (in Seconds)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {exercise?.repDetails?.length == 0 ? (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No Sets Added
                        </TableCell>
                      </TableRow>
                    ) : (
                      exercise?.repDetails?.map((data: any, index: any) => {
                        return (
                          <TableRow>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell>{data.rep}</TableCell>
                            <TableCell>{data.weight}</TableCell>
                            <TableCell>
                              {data?.restTime ? data.restTime : "-"}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Status</Typography>
                  <Typography component="p"></Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Active buy</Typography>
                  <Typography component="p">10k+</Typography>
                </Box>
              </Grid> */}

              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Rating</Typography>
                  Render rating stars
                  <Box display="flex">
                    {renderRatingStars()}
                  </Box>
                </Box>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DetailsExercises;
