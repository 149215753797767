import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
import { WorkoutVideoResponse } from "../types/workoutVideos";
type CommonResponseType = {
  statusCode: number;
  message: string;
};
type GetAllworkoutVideoResponse = {
  data: WorkoutVideoResponse[];
  count: number;
};
type GetTokenParams = {
  // limit?: number;
  page?: number;
  query?: string;
};

type EncryptedBody = {
  hash: string;
  sek: string;
} | null;
export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getworkoutVideos: builder.query<
      CommonResponseType & { data: GetAllworkoutVideoResponse },
      GetTokenParams
    >({
      // query:({page,limit,query})=>{
      query: ({ page, query }) => {
        let url = END_POINTS.workoutVideo;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (query) {
          queryParams.push(`search=${query}`);
        }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    getworkoutVideoById: builder.query<
      CommonResponseType & { data: WorkoutVideoResponse },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.workoutVideo}/${id}`,
        method: "GET",
      }),
    }),
    editworkoutVideoById: builder.mutation<
      CommonResponseType & { data: WorkoutVideoResponse },
      { id: string | undefined; body: EncryptedBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.workoutVideo}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    addworkoutVideo: builder.mutation<
      CommonResponseType & { data: WorkoutVideoResponse },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.workoutVideo,
        method: "POST",
        body,
      }),
    }),

    deleteworkoutVideoById: builder.mutation<
      CommonResponseType & { data: WorkoutVideoResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.workoutVideo}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetworkoutVideosQuery,
  useLazyGetworkoutVideoByIdQuery,
  useAddworkoutVideoMutation,
  useDeleteworkoutVideoByIdMutation,
  useEditworkoutVideoByIdMutation,
} = userApi;
