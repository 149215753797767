import { END_POINTS } from "../helpers";
// import { any } from "../types/trainingPlan";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
  statusCode: number;
  message: string;
};
type GetAllTrainingPlanResponse = {
  data: any;
  // trainingPlan: any[];
  count: number;
};

type GetTokenParams = {
  // limit?: number;
  wpagination?: boolean;
  page?: number;
  query?: string;
};
// type EditUserById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };

export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getTrainingPlan: builder.query<
      CommonResponseType & { data: GetAllTrainingPlanResponse },
      GetTokenParams
    >({
      // query:({page,limit,query})=>{
      query: ({ page, wpagination, query }) => {
        let url = END_POINTS.trainingPlan;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (wpagination === true) {
          queryParams.push(`wpagination=${wpagination}`);
        }
        if (query) {
          queryParams.push(`search=${query}`);
        }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getTrainingPlansById: builder.query<
      CommonResponseType & { data: any },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.trainingPlan}/${id}`,
        method: "GET",
      }),
    }),

    changeTrainingPlanStatus: builder.query<
      CommonResponseType & { data: any },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.changeUserStatus}/${id}`,
        method: "GET",
      }),
    }),

    editTrainingPlanById: builder.mutation<
      CommonResponseType & { data: any },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.trainingPlan}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    deleteTrainingPlanById: builder.mutation<
      CommonResponseType & { data: any },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.trainingPlan}/${id}`,
        method: "DELETE",
      }),
    }),

    addTrainingPlan: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.trainingPlan,
        method: "POST",
        body,
      }),
    }),
  }),
});
export const {
  useLazyGetTrainingPlanQuery,
  useLazyGetTrainingPlansByIdQuery,
  useEditTrainingPlanByIdMutation,
  useAddTrainingPlanMutation,
  useDeleteTrainingPlanByIdMutation,
} = userApi;
