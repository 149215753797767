import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { showError, showToast } from "../../constants/toast";
import {
  useLazyGetUsersQuery,
  useEditUserByIdMutation,
  useDeleteUserByIdMutation,
  useLazyGetUserCSVQuery,
} from "../../services/user";
import { UserResponse } from "../../types/User";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { isValidInput } from "../../utils/validations";
import { Pagination } from "../../components";
import WarnModal from "../../components/modals/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";
import users from "../users";
import {
  useDeleteexerciseByIdMutation,
  useEditexerciseByIdMutation,
  useLazyGetexerciseQuery,
} from "../../services/exercise";
import Loader from "../../helpers/Loader";
import useAuth from "../../hooks/useAuth";
import {
  useDeleteTrainingPlanByIdMutation,
  useEditTrainingPlanByIdMutation,
  useLazyGetTrainingPlanQuery,
} from "../../services/trainingplan";
import { Add } from "@mui/icons-material";
const ManageTrainingPlan = () => {
  const navigate = useNavigate();
  const user = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [trainingPlan, setTrainingPlan] = useState<any>([]);
  const [getTrainingPlan] = useLazyGetTrainingPlanQuery();
  const [updateUserStatus]: any = useEditTrainingPlanByIdMutation();
  const [deleteById] = useDeleteTrainingPlanByIdMutation();
  let totalPages = Math.ceil(totalCount / 10);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const getTrainingPlanList = async () => {
    setIsLoading(true);
    try {
      const response = await getTrainingPlan({
        page: page,
        // limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        console.log("DATA", response);
        setTrainingPlan(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setTrainingPlan([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
  };

  const handleStatusChange = async (index: any) => {
    try {
      const body = {
        isBlocked: !trainingPlan[index]?.isBlocked,
      };
      console.log(body);
      const encryptedData = generateEncryptedKeyBody(body);
      const response = await updateUserStatus({
        id: `${trainingPlan[index]._id}`,
        body: encryptedData,
      }).unwrap();
      showToast(response?.message || "User Updated Successfully");
      setTrainingPlan((prevData: any) => {
        let temp = [...prevData];
        temp[index] = { ...temp[index], isBlocked: !temp[index].isBlocked };
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
      //      console.log(error);
    }
  };
  useEffect(() => {
    //   console.log("TESTING")
    getTrainingPlanList();
  }, [debouncedSearchTerm, page]);
  console.log(trainingPlan);
  // useEffect(() => {
  //   if (userData && userData?.permission?.length) {
  //     checkPermission();
  //   }
  // }, [userData]);

  // Declare rows variable here
  const rows = [
    {
      Name: "Yoga",
      Exetcise_Category: "Aerobic",
      Exetcise_Sub_Category: "Flexibility and Stretching",
      Description: "2 times daily",
    },
    {
      Name: "Stretching",
      Exetcise_Category: "Cardiovascular",
      Exetcise_Sub_Category: "Balance and Stability",
      Description: "3 times daily",
    },
  ];

  // // Sample data for demonstration
  // function createData(Name: any, Exetcise_Category: any, Exetcise_Sub_Category: any, Description: any) {
  //   return { Name, Exetcise_Category, Exetcise_Sub_Category, Description };
  // }

  const [exerciseVisibility, setExerciseVisibility] = useState(
    Array(rows.length).fill(true)
  );

  const toggleVisibility = (index: number) => {
    setExerciseVisibility((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <div className="main_loyout">
      <Loader isLoad={isLoading} />
      <div className="dashboard">
        <h1 className="mn_hdng">Manage Training Plan</h1>
      </div>
      <Card className="cards">
        <Box className="custom_tabs">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right"></Box>
            <Box className="cards_header_right">
              {user?.role == 1 || user?.permissions?.[11]?.isEdit ? (
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("add")}
                >
                  {<Add />}Add Training Plan
                </Button>
              ) : null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Training Plan Name</TableCell>
                  <TableCell>Number of Days</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trainingPlan.length === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      No Training Plan Found
                    </TableCell>
                  </TableRow>
                ) : (
                  trainingPlan?.map((row: any, i: number) => (
                    <TableRow key={i}>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>
                        <figure className="user_img">
                          <img
                            src={
                              row?.image
                                ? row?.image
                                : "/static/images/workoutcategory-placeholder.png"
                            }
                            alt=""
                          />
                        </figure>
                      </TableCell>
                      <TableCell>{row?.trainingPlan}</TableCell>
                      <TableCell>{row?.planDetail?.length}</TableCell>
                      <TableCell>
                        <Switch
                          checked={!row?.isBlocked}
                          disabled={
                            !(
                              user?.role == 1 ||
                              (user?.role == 2 &&
                                user?.permissions?.[11].isEdit)
                            )
                          }
                          onChange={() => handleStatusChange(i)}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() => navigate("details/" + row?._id)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                          {user?.role == 1 ||
                          user?.permissions?.[11]?.isEdit ? (
                            <IconButton
                              onClick={() => navigate("edit/" + row?._id)}
                            >
                              <ModeEditIcon />
                            </IconButton>
                          ) : null}
                          {user?.role == 1 ||
                          user?.permissions?.[11]?.isDelete ? (
                            <IconButton
                              onClick={() => {
                                setSelectedId(row._id);
                                setOpen(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Card>
      <Pagination
        module={trainingPlan}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        setOpen={setOpen}
        open={open}
        name={"training plan"}
        handleDelete={() =>
          handleDelete(deleteById, selectedId, getTrainingPlanList)
        }
      />
    </div>
  );
};

export default ManageTrainingPlan;
