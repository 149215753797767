import { END_POINTS } from "../helpers";
import { RecipeResponse } from "../types/recipe";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetAllRecipeResponse = {
  recipe: RecipeResponse[];
  count: number;
};

type GetTokenParams = {
  // limit?: number;
  page?: number;
  query?: string;
};
// type EditUserById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type EditUserById = {
  hash: string;
  sek: string;
} | null;
export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecipe: builder.query<
      CommonResponseType & { data: GetAllRecipeResponse },
      GetTokenParams
    >({
      // query:({page,limit,query})=>{
      query: ({ page, query }) => {
        let url = END_POINTS.recipe;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (query) {
          queryParams.push(`search=${query}`);
        }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    getRecipesById: builder.query<
      CommonResponseType & { data: RecipeResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.recipe}/${id}`,
        method: "GET",
      }),
    }),
    changeRecipeStatus: builder.query<
      CommonResponseType & { data: RecipeResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.changeUserStatus}/${id}`,
        method: "GET",
      }),
    }),
    editRecipeById: builder.mutation<
      CommonResponseType & { data: RecipeResponse },
      { id: string; body: EditUserById }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.recipe}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteRecipeById: builder.mutation<
      CommonResponseType & { data: RecipeResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.recipe}/${id}`,
        method: "DELETE",
      }),
    }),
    addRecipe: builder.mutation<CommonResponseType & { data: any }, CommonBody>(
      {
        query: (body) => ({
          url: END_POINTS.recipe,
          method: "POST",
          body,
        }),
      }
    ),
  }),
});
export const {
  useLazyGetRecipeQuery,
  useAddRecipeMutation,
  useLazyGetRecipesByIdQuery,
  useDeleteRecipeByIdMutation,
  useEditRecipeByIdMutation,
} = userApi;
