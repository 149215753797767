import React, { useEffect, useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { showError } from "../../constants/toast";
import { useLazyGetSubscriptionHistoryQuery } from "../../services/subscriptionHistory";
import useAuth from "../../hooks/useAuth";
import { UserResponse } from "../../types/User";
import { Pagination } from "../../components";
import { isValidInput } from "../../utils/validations";
import Loader from "../../helpers/Loader";
import moment from "moment";
import { SubscriptionHistoryResopnse } from "../../types/subscription";
import { useLazyGetUsersByIdQuery } from "../../services/user";

const SubscriptionHistory = () => {
    // user?.permissions?.[0]?.isDelete
    const user = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
    const [totalCount, setTotalCount] = useState<number>(0);
    const [page, setPage] = useState(1);
    const [users, setUsers] = useState<UserResponse[]>([]);
    const [subscriptionHistoryData, setSubscriptionHistoryData] = useState<SubscriptionHistoryResopnse[]>([])
    const [hidePermission, setHidePermission] = useState<
        Permissions | null | undefined
    >(null);
    const [getSubscriptionHistory] = useLazyGetSubscriptionHistoryQuery()

    const [getUser] = useLazyGetUsersByIdQuery();
    let totalPages = Math.ceil(totalCount / 10);

    const onPageChange = (newPage: number) => {
        setPage(newPage);
    };

      const getSubscriptionHistoryList = async () => {
        setIsLoading(true);
        try {
          const response = await getSubscriptionHistory({
            page: page,
            size: 10,
            query: debouncedSearchTerm.trim(),
          }).unwrap();
          if (response?.statusCode === 200) {
            // console.log("hlo")
            setSubscriptionHistoryData(response?.data?.subscription || []);
            setTotalCount(response?.data?.count);
          } else {
            setSubscriptionHistoryData([]);
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
        setIsLoading(false);
      };

   
      useEffect(() => {
        getSubscriptionHistoryList();
      }, [debouncedSearchTerm, page]);
    //   console.log(users);

    const label = { inputProps: { "aria-label": "Switch demo" } };

    return (
        <>
            <div className="main_loyout">
                <Loader isLoad={isLoading} />

                <div className="dashboard">
                    <h1 className="mn_hdng">Subscription History</h1>
                </div>
                <Card className="cards">
                    <Box className="cards_header">
                        <SearchBar
                            searchTerm={searchTerm}
                            setDebouncedSearchTerm={setDebouncedSearchTerm}
                            value={searchTerm}
                            onCross={() => setSearchTerm("")}
                            onChange={(val: any) => {
                                if (isValidInput(val.target.value)) {
                                    setSearchTerm(val.target.value);
                                }
                            }}
                        />
                    </Box>
                    <TableContainer className="table_container">
                        <Box className="heading"></Box>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">S.No</TableCell>
                                    <TableCell align="center" >Image</TableCell>
                                    <TableCell align="center" >Subscription name</TableCell>
                                    <TableCell align="center" >Subscription price</TableCell>
                                    <TableCell align="center" >User name</TableCell>
                                    <TableCell align="center" >User Phone number</TableCell>
                                    <TableCell align="center" >User Email</TableCell>
                                    <TableCell align="center" >Start date</TableCell>
                                    <TableCell align="center" >End date</TableCell>
                                </TableRow>
                            </TableHead>
                            {subscriptionHistoryData?.length ? (
                subscriptionHistoryData?.map((item, i) => {
                  return (
                            <TableBody
                             key={item?._id}
                            >
                                <TableRow>
                                    <TableCell align="center">
                                        {" "}
                                        {(page - 1) * 10 + i + 1}
                                    </TableCell>
                                    <TableCell align="center">
                                        <figure className="user_img">
                                            <img
                                                src={
                                                    item?.userId?.image ?  item?.userId?.image :   "/static/images/user_placeholder.png"
                                                }
                                                alt=""
                                            />
                                        </figure>
                                    </TableCell>
                                    <TableCell align="center">{item?.planName || "-"}</TableCell>
                                    <TableCell align="center">{item?.price || "0"}</TableCell>
                                    <TableCell align="center">{item?.userId?.fullName || "-"}</TableCell>
                                    <TableCell align="center">{item?.userId?.phone || "-"}</TableCell>
                                    <TableCell align="center">{item?.userId?.email || "-"}</TableCell>
                                    <TableCell align="center">{moment(item?.createdAt).format('YYYY-MM-DD') || "-"}</TableCell>
                                    <TableCell align="center">{moment(item?.expiryDate).format('YYYY-MM-DD') || "-"}</TableCell>
                                </TableRow>
                            </TableBody>
                         );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    {"No Subscription History Found"}
                  </TableCell>
                </TableBody>
              )} 
                        </Table>
                    </TableContainer>
                </Card>
                <Pagination
                    module={subscriptionHistoryData}
                    page={page}
                    onPageChange={onPageChange}
                    totalPages={totalPages}
                />
            </div>
        </>
    );
};

export default SubscriptionHistory;
