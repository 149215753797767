import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { SIDEBAR_WIDTH } from "../constants";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import KitesurfingIcon from "@mui/icons-material/Kitesurfing";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import { PropsWithoutRef } from "react";
import useAuth from "../hooks/useAuth";
import React from "react";
import { PlayForWorkOutlined, PunchClock } from "@mui/icons-material";
interface Props {
  mobileOpen: boolean;
  setMobileOpen: (arg0: boolean) => void;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  setMobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAuth();
  const drawer = (
    <>
      <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
        <figure className="logo_cntnr">
          <img src="/static/images/logo.png" alt="" />
        </figure>

        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
            display: { lg: "none" },
          }}
          onClick={handleSidebarToggle}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <List sx={{ flexGrow: 1 }} className="sidebr-lst">
        <ListItemButton
          className="lst-itm"
          selected={location?.pathname?.includes("dashboard")}
          onClick={() => {
            setMobileOpen(false);
            navigate("/dashboard");
          }}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Dashboard" />
        </ListItemButton>

        {/* users */}

        {user?.role == 1 || user?.permissions?.[0]?.isView ? (
          <ListItemButton
            className="lst-itm"
            selected={location?.pathname?.includes("manage-users")}
            onClick={() => {
              setMobileOpen(false);
              navigate("/manage-users");
            }}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText className="lstitm-txt" primary="Manage Users" />
          </ListItemButton>
        ) : null}

        {/* exercises */}
        {user?.role == 1 || user?.permissions?.[10]?.isView ? (
          <ListItemButton
            className="lst-itm"
            selected={location?.pathname?.includes("manage-exercises")}
            onClick={() => {
              setMobileOpen(false);
              navigate("/manage-exercises");
            }}
          >
            <ListItemIcon>
              <FitnessCenterIcon />
            </ListItemIcon>
            <ListItemText className="lstitm-txt" primary="Manage Exercises" />
          </ListItemButton>
        ) : null}

        {/* training plan */}
        {user?.role == 1 || user?.permissions?.[11]?.isView ? (
          <ListItemButton
            className="lst-itm"
            selected={location?.pathname?.includes("manage-training-plan")}
            onClick={() => {
              setMobileOpen(false);
              navigate("/manage-training-plan");
            }}
          >
            <ListItemIcon>
              <PunchClock />
            </ListItemIcon>
            <ListItemText
              className="lstitm-txt"
              primary="Manage Training Plan"
            />
          </ListItemButton>
        ) : null}

        {/* recipes */}

        {user?.role == 1 || user?.permissions?.[1]?.isView ? (
          <ListItemButton
            className="lst-itm"
            selected={location?.pathname?.includes("manage-recipes")}
            onClick={() => {
              setMobileOpen(false);
              navigate("/manage-recipes");
            }}
          >
            <ListItemIcon>
              <DinnerDiningIcon />
            </ListItemIcon>
            <ListItemText className="lstitm-txt" primary="Manage Recipes" />
          </ListItemButton>
        ) : null}

        {/* {   (user?.role==1 || (user?.permissions?.[2]?.isView))? <ListItemButton
          className="lst-itm"
          selected={location?.pathname?.includes("workout-videos-management")}
          onClick={() => {
            setMobileOpen(false);navigate("/workout-videos-management")}}
        >
          <ListItemIcon>
            <SportsGymnasticsIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Workout Videos" />
        </ListItemButton>:null} */}

        {/* bodtscan */}

        {/* biohacking */}

        {user?.role == 1 || user?.permissions?.[3]?.isView ? (
          <ListItemButton
            className="lst-itm"
            selected={location?.pathname?.includes("manage-bio-hacking")}
            onClick={() => {
              setMobileOpen(false);
              navigate("/manage-bio-hacking");
            }}
          >
            <ListItemIcon>
              <KitesurfingIcon />
            </ListItemIcon>
            <ListItemText className="lstitm-txt" primary="Manage Bio-Hacking" />
          </ListItemButton>
        ) : null}

        {/* fasting */}

        {user?.role == 1 || user?.permissions?.[4]?.isView ? (
          <ListItemButton
            className="lst-itm"
            selected={location?.pathname?.includes("manage-fasting")}
            onClick={() => {
              setMobileOpen(false);
              navigate("/manage-fasting");
            }}
          >
            <ListItemIcon>
              <CrisisAlertIcon />
            </ListItemIcon>
            <ListItemText className="lstitm-txt" primary="Manage Fasting" />
          </ListItemButton>
        ) : null}

        {user?.role == 1 || user?.permissions?.[5]?.isView ? (
          <ListItemButton
            className="lst-itm"
            selected={location?.pathname?.includes("manage-subscription")}
            onClick={() => {
              setMobileOpen(false);
              navigate("/manage-subscription");
            }}
          >
            <ListItemIcon>
              <SubscriptionsIcon />
            </ListItemIcon>
            <ListItemText
              className="lstitm-txt"
              primary="Manage Subscription"
            />
          </ListItemButton>
        ) : null}


        {/* subscription history */}

        <ListItemButton
            className="lst-itm"
            selected={location?.pathname?.includes("subscription-history")}
            onClick={() => {
              setMobileOpen(false);
              navigate("/subscription-history");
            }}
          >
            <ListItemIcon>
              <SubscriptionsIcon />
            </ListItemIcon>
            <ListItemText
              className="lstitm-txt"
              primary="Subscription History"
            />
          </ListItemButton>

        {/* workout videos management */}

        {/* sub-admin */}
        {user?.role == 1 ? (
          <ListItemButton
            className="lst-itm"
            selected={location?.pathname?.includes("manage-subadmin")}
            onClick={() => {
              setMobileOpen(false);
              navigate("/manage-subadmin");
            }}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText className="lstitm-txt" primary="Manage Sub-Admin" />
          </ListItemButton>
        ) : null}

        {/* survey questions */}

        {user?.role == 1 || user?.permissions?.[6]?.isView ? (
          <ListItemButton
            className="lst-itm"
            selected={location?.pathname?.includes(
              "manage-survey-questionnaries"
            )}
            onClick={() => {
              setMobileOpen(false);
              navigate("/manage-survey-questionnaries");
            }}
          >
            <ListItemIcon>
              <QuestionMarkIcon />
            </ListItemIcon>
            <ListItemText
              className="lstitm-txt"
              primary="Manage Survey Questionnaries"
            />
          </ListItemButton>
        ) : null}

        {/* notifications */}

        {user?.role == 1 || user?.permissions?.[7]?.isView ? (
          <ListItemButton
            className="lst-itm"
            selected={location?.pathname?.includes("manage-notifications")}
            onClick={() => {
              setMobileOpen(false);
              navigate("/manage-notifications");
            }}
          >
            <ListItemIcon>
              <NotificationsActiveIcon />
            </ListItemIcon>
            <ListItemText
              className="lstitm-txt"
              primary=" Notification Management"
            />
          </ListItemButton>
        ) : null}

        {user?.role == 1 || user?.permissions?.[8]?.isView ? (
          <ListItemButton
            className="lst-itm"
            selected={location?.pathname?.includes("analytics")}
            onClick={() => {
              setMobileOpen(false);
              navigate("/analytics");
            }}
          >
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText className="lstitm-txt" primary="Analytics" />
          </ListItemButton>
        ) : null}

        {user?.role == 1 || user?.permissions?.[9]?.isView ? (
          <ListItemButton
            className="lst-itm"
            selected={location?.pathname?.includes("cms")}
            onClick={() => {
              setMobileOpen(false);
              navigate("/cms");
            }}
          >
            <ListItemIcon>
              <SummarizeTwoToneIcon />
            </ListItemIcon>
            <ListItemText className="lstitm-txt" primary="Manage CMS" />
          </ListItemButton>
        ) : null}
      </List>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
