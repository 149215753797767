import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Input,
  IconButton,
  CardMedia,
  Switch,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useNavigate, useParams } from "react-router-dom";
import EditText from "../../components/EditText";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import { showError, showToast } from "../../constants/toast";
import { UploadVideo, UploadMedia } from "../../utils/uploadMedia";
import Loader from "../../helpers/Loader";
import { Cancel, CameraAlt } from "@mui/icons-material";
import { errorToast } from "../../helpers";
import { isNumber } from "../../utils/validations";
import {
  useAddRecipeMutation,
  useDeleteRecipeByIdMutation,
  useEditRecipeByIdMutation,
  useLazyGetRecipesByIdQuery,
} from "../../services/recipe";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import WarnModal from "../../components/modals/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";
interface Recipe {
  question: string;
  options: string[];
}

interface Nutrition {
  calorie: string;
  nutrition: string;
  amountPerServing: string;
}

const Recipe = ({
  type,
  snacks,
  setSnacks,
  selectedDiet,
  getRecipeByDietData,
}: any) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [imageArray, setImageArray] = useState<any>([]);
  const [videoArray, setVideoArray] = useState<any>([]);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [selectedId2, setSelectedId2] = useState(0);
  const [serving, setServing] = useState<any>("");
  const [portionSize, setPortionSize] = useState<any>("");
  // const[videoLink,setVideoLink]=useState("");
  const [currentRecipeData, setCurrentRecipeData] = useState<any>({});
  const [currentRecipeId, setCurrentRecipeId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [addedRecipe, setAddedRecipe] = useState("");
  const [addNotes, setaddNotes] = useState("");
  const [description, setDescription] = useState("");
  const [directions, setDirections] = useState("");
  const [nutritionData, setNutritionData] = useState<any>([]);
  const [nutrition, setNutrition] = useState("");
  const [image, setImage] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [newRecipe, setNewRecipe] = useState("");
  const [selectedRecipeName, setSelectedRecipeName] = useState("");
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState<string>("");
  const [calorie, setCalorie] = useState<string>("");
  const [error, setError] = useState(false);
  const [dietData, setDietData] = useState([]);
  const [currentRecipeName, setCurrentRecipeName] = useState("");
  const [recipeTime, setRecipeTime] = useState<string>("");
  const [amountPerServing, setAmountPerServing] = useState("");
  const [displayEditText, setDisplayEditText] = useState(true);
  const [selectedId, setSelectedId] = useState<any>("");
  //    console.log("ID",currentRecipeId);

  //mutation and queries
  const [addRecipe] = useAddRecipeMutation();
  const [getRecipeById] = useLazyGetRecipesByIdQuery();
  const [deleteRecipeById] = useDeleteRecipeByIdMutation();
  const [updateRecipeById] = useEditRecipeByIdMutation();
  const handleAddOption = (
    recipe: Recipe,
    setRecipe: React.Dispatch<React.SetStateAction<Recipe>>
  ) => {
    setRecipe((prevRecipe) => ({
      ...prevRecipe,
      options: [...prevRecipe.options, ""],
    }));
  };

  const handleRemoveOption = (
    index: number,
    recipe: any,
    setRecipe: React.Dispatch<React.SetStateAction<any>>
  ) => {
    if (recipe?.length === 0) {
      return;
    }
    setRecipe((prevRecipe: any) =>
      prevRecipe.filter((x: any, i: any) => i !== index)
    );
  };
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true);
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        showError("Invalid file");
        return;
      }
      const allowedExtensions = ["png", "jpg", "jpeg", "gif"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      console.log("sssssssssssssssssssss", fileExtension);
      if (!allowedExtensions.includes(fileExtension || "")) {
        setLoading(false);
        showError("Invalid file format: only png, jpg, gif images are allowed");
        return;
      }
      const res = await UploadMedia(file);
      ////    //      console.log(res,'lklk');

      if (res?.statusCode === 200) {
        setImageArray((prevData: any) => {
          return [...imageArray, res?.data];
        });
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
    setLoading(false);
  };
  console.log(videoArray);
  const captureVideoThumbnail = async (videoBlob: Blob): Promise<string> => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.src = URL.createObjectURL(videoBlob);

      video.addEventListener("loadedmetadata", () => {
        // Ensure that the video's duration is greater than zero
        if (video.duration > 0) {
          // Seek to the middle of the video
          const duration = video.duration;
          const middle = duration / 2;
          video.currentTime = middle;

          video.addEventListener("seeked", () => {
            const canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            const context = canvas.getContext("2d");
            context?.drawImage(video, 0, 0, canvas.width, canvas.height);

            const thumbnailDataUrl = canvas.toDataURL("image/jpeg");

            video.remove();
            canvas.remove();

            resolve(thumbnailDataUrl);
          });
        } else {
          // Handle the case where the video's duration is zero or undefined
          showError("Invalid video file: video duration is zero or undefined");
          video.remove();
          resolve("");
        }
      });
    });
  };
  const handleVideoUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    const allowedExtensions = ["mp4"];
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    console.log("File extension:", fileExtension);
    if (!allowedExtensions.includes(fileExtension || "")) {
      setLoading(false);
      showError("Invalid file format: only .mp4 files are allowed");
      return;
    }
    setLoading(true);
    console.log("Starting file upload");

    const reader = new FileReader();

    reader.onloadend = async () => {
      try {
        const dataUrl = reader.result as string;

        if (!dataUrl.startsWith("data:") || !dataUrl.includes(",")) {
          throw new Error("Invalid data URL");
        }

        // Create a video element
        const video = document.createElement("video");
        video.src = dataUrl;
        video.muted = true;
        video.playsInline = true;

        // Wait for the video to load metadata
        await new Promise<void>((resolve, reject) => {
          video.onloadedmetadata = () => {
            resolve();
          };
          video.onerror = (error) => {
            reject(error);
          };
        });

        console.log("Video metadata loaded");

        // Set the current time to the middle of the video
        video.currentTime = video.duration / 2;

        // Wait for the video to seek to the middle frame
        await new Promise<void>((resolve, reject) => {
          video.onseeked = () => {
            resolve();
          };
          video.onerror = (error) => {
            reject(error);
          };
        });

        console.log("Video seeked to the middle frame");

        // Create a canvas element to capture the frame
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext("2d");

        if (!context) {
          throw new Error("Failed to get canvas context");
        }

        // Draw the frame on the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Get the thumbnail data URL from the canvas
        const thumbnailDataUrl = canvas.toDataURL("image/jpeg");

        console.log("Thumbnail captured");

        // Convert the thumbnail data URL to Blob
        const thumbnailBlob = await (await fetch(thumbnailDataUrl)).blob();

        // Upload the video
        const videoUploadResponse = await UploadVideo(file);
        const thumbnailUploadResponse = await UploadMedia(thumbnailBlob);

        if (
          videoUploadResponse?.statusCode === 200 &&
          thumbnailUploadResponse?.statusCode === 200
        ) {
          setVideoArray((prevData: any) => {
            return [
              ...prevData,
              {
                video: videoUploadResponse?.data,
                thumbnail: thumbnailUploadResponse?.data,
              },
            ];
          });
          setLoading(false);
        } else {
          setLoading(false);
          showError("Error uploading video or thumbnail");
        }
      } catch (error) {
        console.error("Error during file upload:", error);
        setLoading(false);
        showError("An error occurred during the upload process");
      }
    };

    // Read the file as a data URL
    reader.readAsDataURL(file);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    // setCurrentRecipeName("");
    setImageArray([]);
    setDirections("");
    setDescription("");
    setaddNotes("");
    setRecipeTime("");
    setNutritionData([]);
    // setVideoLink("")
    setVideoArray([]);
    setCurrentRecipeId("");
    setError(false);
    setServing("");
    setPortionSize("");
    setOpenModal(false);
  };

  const handleAddRole = (event: React.FormEvent) => {
    event.preventDefault();
    if (nutrition == "") {
      showError("Nutrition is required");
      return;
    }
    if (calorie == "") {
      showError("Nutrition quantity is required");
      return;
    }
    if (nutrition?.length > 40) {
      showError("Nutrition length can't exceed 40");
      return;
    }
    // if(parseInt(calorie)>30000)
    //   {
    //     showError("Calorie can't exceed 30000");
    //     return;
    //   }
    if (nutrition.trim() !== "") {
      const newNutritionData = { nutrition, calorie };
      setNutritionData([...nutritionData, newNutritionData]);
      setNutrition("");
      setCalorie("");
    }
  };

  const deleteRecipeByIdData = async (recipeId: any) => {
    try {
      const response = await deleteRecipeById({ id: recipeId }).unwrap();
      if (response.statusCode == 200) {
        showToast("Recipe deleted successfully");
        getRecipeByDietData();
      }
    } catch (error: any) {
      showError(error.data.message);
    }
  };

  const getRecipeByIdData = async (recipeId: any) => {
    try {
      setLoading(true);
      setDisplayEditText(false);
      const response: any = await getRecipeById({ id: recipeId }).unwrap();
      if (response?.statusCode == 200) {
        console.log("SUCCCESSSSS", response.data);
        setServing(response?.data?.serving);
        setPortionSize(response?.data?.portionSize);
        setCurrentRecipeData(response.data);
        setCurrentRecipeName(response.data.name);
        setImageArray(response.data.image);
        // setVideoLink(response.data.videolink)
        setVideoArray(response.data.videolink);
        setDirections(response.data.directions);
        setDescription(response.data.ingredients);
        setaddNotes(response.data.notes);
        setRecipeTime(response.data.prepTime);
        setNutritionData(response.data.nutritionArray);
      }
    } catch (error: any) {
      showError(error.data.message);
    } finally {
      setLoading(false);
      setDisplayEditText(true);
    }
  };
  useEffect(() => {
    console.log("FUNCTION CALL", currentRecipeId);
    if (currentRecipeId) getRecipeByIdData(currentRecipeId);
  }, [currentRecipeId]);
  console.log(imageArray);
  const handleUpdateRecipe = async () => {
    const body = {
      name: currentRecipeName,
      diet: selectedDiet,
      image: imageArray,
      directions: directions,
      ingredients: description,
      notes: addNotes,
      prepTime: recipeTime,
      type: type,
      serving: serving,
      // serving:serving,
      nutritionArray: nutritionData,
      videolink: videoArray,
      portionSize: portionSize ? portionSize : 1,
    };
    const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
    try {
      const response = await updateRecipeById({
        id: currentRecipeId,
        body: encryptedBody,
      }).unwrap();
      if (response.statusCode == 200) {
        showToast("Recipe updated successfully");
      }
    } catch (error: any) {
      showError(error.data.message);
    }
  };

  const handleStatusChange = async (id: string, isBlocked: boolean) => {
    try {
      const body = {
        isBlocked: !isBlocked,
      };
      console.log(body);
      const encryptedData = generateEncryptedKeyBody(body);
      const response = await updateRecipeById({
        id: id,
        body: encryptedData,
      }).unwrap();
      showToast("Recipe Updated Successfully");
      getRecipeByDietData();
    } catch (error: any) {
      showError(error?.data?.message || "");
      //      console.log(error);
    }
  };
  const handleAddRecipe = async () => {
    const body = {
      name: newRecipe,
      diet: selectedDiet,
      image: imageArray,
      directions: directions,
      ingredients: description,
      notes: addNotes,
      prepTime: recipeTime,
      type: type,
      // serving:serving,
      serving: serving,
      nutritionArray: nutritionData,
      videolink: videoArray,
      portionSize: portionSize ? portionSize : 1,
    };
    const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
    try {
      const response = await addRecipe(encryptedBody).unwrap();
      if (response.statusCode == 200) {
        ////        console.log("body",body);
      }
    } catch (error: any) {
      showError(error.data.message);
    }
  };

  const handleMediaCancel = () => {
    setImage("");
    setVideo("");
    setFileName("");
  };

  const handleDeleteRole = (index: number) => {
    const updatedRolesData = [...nutritionData];
    updatedRolesData.splice(index, 1);
    setNutritionData(updatedRolesData);
  };

  return (
    <Grid container spacing={3}>
      {snacks.map((item: any, index: any) => {
        return (
          item.type == type && (
            <Grid item xs={12}>
              <Box
                className="faq_box"
                sx={{ bgcolor: "#FFFFFF", marginBlock: "-30px" }}
              >
                <FormControl sx={{ mb: 2, width: "100%" }}>
                  <Box key={index} sx={{ display: "flex" }}>
                    <TextField
                      hiddenLabel
                      type="text"
                      name={item.name}
                      disabled={true}
                      variant="outlined"
                      value={item.name}
                      // onChange={(e) => handleOptionChange(index, e.target.value, snacks, setSnacks)}
                      placeholder={`Recipe name`}
                      sx={{ mt: 1, width: "65%" }}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Switch
                        checked={!item.isBlocked}
                        onChange={() =>
                          handleStatusChange(item._id, item.isBlocked)
                        }
                      />
                    </div>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpen2(true);
                        setSelectedId(item._id);
                      }}
                      style={{ marginTop: "0.5rem", marginLeft: "1rem" }}
                    >
                      <RemoveIcon />
                    </Button>

                    {/* <Button
                                    variant="contained"
                                    style={{ marginTop: '0.5rem', marginLeft: '1rem' }}
                                    onClick={() => handleAddOption(snacks, setSnacks)}
                                >
                                    <AddIcon />
                                </Button> */}
                    <Button
                      variant="contained"
                      style={{ marginTop: "0.5rem", marginLeft: "1rem" }}
                      onClick={() => {
                        //                                        // console.log(snacks);
                        handleOpenModal();
                        setCurrentRecipeId(item._id);
                        setSelectedRecipeName(item.name);
                        // getRecipeByIdData(currentRecipeId);
                      }}
                    >
                      <ModeEditIcon />
                    </Button>
                  </Box>
                </FormControl>
              </Box>
            </Grid>
          )
        );
      })}
      <Grid item xs={12}>
        <Box className="faq_box" sx={{ bgcolor: "#FFFFFF" }}>
          <FormControl sx={{ mb: 2, width: "100%" }}>
            <Box key={3} sx={{ display: "flex" }}>
              <TextField
                hiddenLabel
                type="text"
                name="recipee"
                variant="outlined"
                value={newRecipe}
                onChange={(e) => {
                  if (e.target.value.length <= 70) setNewRecipe(e.target.value);

                  if (e.target.value.length > 70) {
                    showError("Recipe name cant exceed 70 characters");
                    return;
                  }
                }}
                placeholder={`Recipe name `}
                sx={{ mt: 1, width: "65%" }}
              />

              {/* <Button
                                        variant="contained"
                                        onClick={() => handleRemoveOption(3, snacks, setSnacks)}
                                        style={{ marginTop: '0.5rem', marginLeft: '1rem' }}
                                    >
                                        <RemoveIcon />
                                    </Button> */}

              <Button
                variant="contained"
                disabled={newRecipe == ""}
                style={{ marginTop: "0.5rem", marginLeft: "1rem" }}
                onClick={(e) => {
                  // setSnacks((prevData:any)=>{return [...prevData,{recipeName:newRecipe}]})
                  // setNewRecipe("");
                  if (newRecipe?.length > 70) {
                    showError("Recipe name can't exceed 70 characters");
                    return;
                  }
                  setOpenModal(true);
                }}
              >
                <AddIcon />
              </Button>
              {/* <Button
                                    variant="contained"
                                    style={{ marginTop: '0.5rem', marginLeft: '1rem' }}
                                    onClick={handleOpenModal}
                                >
                                    <ModeEditIcon />
                                </Button> */}
            </Box>
          </FormControl>
        </Box>
      </Grid>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        className="revert_dialog"
      >
        <Loader isLoad={loading} />

        <DialogContent>
          <FormControl fullWidth>
            {currentRecipeId && (
              <Grid
                item
                lg={4}
                md={4}
                sm={4}
                xs={12}
                sx={{ paddingBottom: "10px" }}
              >
                <Typography
                  className="dialog_title"
                  style={{ paddingTop: "20px" }}
                >
                  Recipe Name
                </Typography>

                <TextField
                  hiddenLabel
                  type={"text"}
                  name="recipeName"
                  variant="outlined"
                  fullWidth
                  placeholder="Recipe Time"
                  value={currentRecipeName}
                  onChange={(event) => {
                    if (event.target.value.length <= 70)
                      setCurrentRecipeName(event.target.value);
                    if (event.target.value.length > 70)
                      showError("Recipe name cant exceed 70 chracters");
                  }}
                />
                {error && currentRecipeName == "" ? (
                  <h6 className="err_msg">Recipe name is required</h6>
                ) : (
                  ""
                )}
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{ paddingTop: "5px", paddingBottom: "10px" }}
            >
              {imageArray?.length < 4 && (
                <Typography className="custom_label">Image</Typography>
              )}
              {imageArray?.length < 4 && (
                <Box className="upload_image">
                  <label htmlFor="icon-button-file">
                    <Input
                      sx={{ display: "none" }}
                      id="icon-button-file"
                      type="file"
                      inputProps={{
                        accept: "image/png,image/jpeg,image/gif",
                      }}
                      onChange={(e: any) => {
                        handleImageUpload(e);
                        e.target.value = null;
                      }}
                    />
                    <Button
                      component="span"
                      className="upload_image_btn3"
                      disabled={imageArray?.length > 4}
                    >
                      <img src="/static/images/recipe-placeholder.png" alt="" />
                      <CameraAlt />
                    </Button>
                  </label>
                </Box>
              )}

              {imageArray?.length > 0 && (
                <Typography
                  className="custom_label"
                  sx={{ marginBlock: "10px" }}
                >
                  Uploaded Images
                </Typography>
              )}
              {imageArray.map((row: any, index: number) => {
                return (
                  <div
                    key={row}
                    className="upload_image_preview3"
                    style={{ marginInline: "2px" }}
                  >
                    <CardMedia component="img" image={row} alt="photo" />
                    <Cancel
                      onClick={(e: any) => {
                        e.preventDefault();
                        setImageArray((prevData: any) => {
                          //  const temp=[...prevData];
                          //  temp.splice(index,1);
                          //  return temp;
                          return prevData.filter((data: any, i: number) => {
                            return i !== index;
                          });
                        });
                      }}
                    />
                  </div>
                );
              })}
              {error && imageArray?.length == 0 ? (
                <h6 className="err_msg">Recipe image is required</h6>
              ) : (
                ""
              )}
            </Grid>

            <Grid item lg={6} md={4} sm={4} xs={12}>
              {videoArray?.length < 4 ? (
                <Typography className="custom_label">{"Video"}</Typography>
              ) : null}
              {videoArray?.length < 4 && (
                <Box
                  className="upload_image_bnr"
                  sx={{ marginBottom: "15px", width: "300px", height: "180px" }}
                >
                  <label htmlFor="icon-button-file-vdo">
                    <Input
                      sx={{ display: "none" }}
                      id="icon-button-file-vdo"
                      type="file"
                      inputProps={{
                        accept: "video/*",
                      }}
                      onChange={(e: any) => {
                        handleVideoUpload(e);
                        e.target.value = null;
                      }}
                    />
                    <Button component="span" className="upload_image_btn">
                      <AddIcon />
                    </Button>
                  </label>
                </Box>
              )}
              {videoArray?.length > 0 && (
                <Typography
                  className="custom_label"
                  sx={{ marginBlock: "10px" }}
                >
                  Uploaded Videos
                </Typography>
              )}

              {videoArray.map((row: string, index: number) => {
                return (
                  <div
                    key={row}
                    className="upload_image_preview2"
                    style={{ width: "300px", height: "170px" }}
                  >
                    {/* <Typography className="custom_label" sx={{marginBlock:"10px"}}>{index}</Typography> */}
                    <video controls width="100%" height="100%">
                      <source
                        //@ts-ignore
                        src={row?.video}
                        type="video/mp4"
                      />
                    </video>
                    <Cancel
                      onClick={(e: any) => {
                        e.preventDefault();
                        console.log(index);
                        setVideoArray((prevData: any) => {
                          return prevData.filter(
                            (data: any, i: number) => i !== index
                          );
                        });
                      }}
                    />
                  </div>
                );
              })}
              {/* { error && videoLink==""? (
                        <h6 className="err_msg">Recipe video is required</h6>
                      ) : (
                        ""
                      )} */}
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <Typography
                className="dialog_title"
                style={{ paddingTop: "20px" }}
              >
                Serving
              </Typography>

              <TextField
                hiddenLabel
                type={"text"}
                name="serving"
                variant="outlined"
                fullWidth
                placeholder="Serving"
                value={serving}
                onChange={(event) => {
                  if (
                    (isNumber(event.target.value) &&
                      parseInt(event.target.value) <= 50) ||
                    event.target.value == ""
                  )
                    setServing(event.target.value);
                  if (parseInt(event.target.value) > 50) {
                    showError("Serving cant exceed 50 serves");
                  }
                }}
              />
              {error && serving == "" ? (
                <h6 className="err_msg">Serving is required</h6>
              ) : (
                ""
              )}
            </Grid>
            {/* <Grid item lg={4} md={4} sm={4} xs={12}>
                                        <Typography className="dialog_title" style={{ paddingTop: '20px' }}>Amount per serving</Typography>

                                        <TextField
                                            hiddenLabel
                                            type={"text"}
                                            name="portionSize"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Amount per serving"
                                            value={portionSize}
                                            onChange={(event) => 
                                               { 
                                                    setPortionSize(event.target.value)
                                            }}
                                        />
                                        { error && portionSize==""? (
                        <h6 className="err_msg">Amount per serving is required</h6>
                      ) : (
                        ""
                      )}
                                    </Grid> */}
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <Typography
                className="dialog_title"
                style={{ paddingTop: "20px" }}
              >
                Preparation Time(in minutes)
              </Typography>

              <TextField
                hiddenLabel
                type={"text"}
                name="recipeTime"
                variant="outlined"
                fullWidth
                placeholder="Preparation Time"
                value={recipeTime}
                onChange={(event) => {
                  if (
                    (isNumber(event.target.value) &&
                      parseInt(event.target.value) <= 999) ||
                    event.target.value == ""
                  ) {
                    setRecipeTime(event.target.value);
                  }
                  if (parseInt(event.target.value) > 999)
                    showError("Preparation time cant exceed 999 minutes");
                }}
              />
              {error && recipeTime == "" ? (
                <h6 className="err_msg">Preparation time is required</h6>
              ) : (
                ""
              )}
            </Grid>
            <Grid>
              <Typography
                className="dialog_title"
                style={{ marginTop: "20px" }}
              >
                Ingredients
              </Typography>

              {displayEditText ? (
                <div style={{ width: "80%" }}>
                  <EditText content={description} setContent={setDescription} />
                </div>
              ) : null}
              {error && description == "" ? (
                <h6 className="err_msg">Ingredients are required</h6>
              ) : (
                ""
              )}
            </Grid>
            <Grid>
              <Typography
                className="dialog_title"
                style={{ marginTop: "20px" }}
              >
                Directions
              </Typography>

              <div style={{ width: "80%" }}>
                {displayEditText ? (
                  <EditText content={directions} setContent={setDirections} />
                ) : null}
              </div>
              {/* { error && directions==""? (
                        <h6 className="err_msg">Directions are required</h6>
                      ) : (
                        ""
                      )} */}
            </Grid>
            <form onSubmit={handleAddRole}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={3}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography
                      className="dialog_title"
                      style={{ paddingTop: "20px" }}
                    >
                      Nutrition
                    </Typography>

                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="nutrition"
                      variant="outlined"
                      fullWidth
                      placeholder="Add Nutrition"
                      value={nutrition}
                      onChange={(event) => {
                        if (event.target.value.length <= 40)
                          setNutrition(event.target.value);
                        else showError("Nutriton cant exceed 40 characters");
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography
                      className="dialog_title"
                      style={{ paddingTop: "20px" }}
                    >
                      Quantity
                    </Typography>

                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="calorie"
                      variant="outlined"
                      fullWidth
                      placeholder="Enter quantity"
                      value={calorie}
                      onChange={(event) => {
                        if (calorie.length <= 30)
                          setCalorie(event.target.value);
                        else
                          showError(
                            "Nutriton quantity cant exceed 30 characters"
                          );
                      }}
                    />
                  </Grid>
                  <div
                    className="form_btn"
                    style={{ display: "flex", alignItems: "end" }}
                  >
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                      style={{ marginLeft: "1rem", marginTop: "0.5rem" }}
                    >
                      Add
                    </Button>
                  </div>
                </Grid>
              </CardContent>
            </form>
            <Grid item xs={12}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="mn_hdng">S.No.</TableCell>
                      <TableCell className="mn_hdng">Nutrition</TableCell>
                      <TableCell className="mn_hdng">Quantity</TableCell>

                      <TableCell className="mn_hdng">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {nutritionData?.length ? (
                    nutritionData?.map((row: Nutrition, i: number) => (
                      <TableBody key={i}>
                        <TableRow>
                          <TableCell>{i + 1}</TableCell>
                          <TableCell>{row.nutrition}</TableCell>
                          <TableCell>{row.calorie}</TableCell>

                          <TableCell>
                            <IconButton
                              onClick={() => {
                                setSelectedId2(i);
                                setOpen3(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={4} style={{ textAlign: "center" }}>
                          No Nutrition Added
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
                <Grid>
                  <Typography
                    className="dialog_title"
                    style={{ marginTop: "20px" }}
                  >
                    Notes
                  </Typography>

                  {displayEditText ? (
                    <div style={{ width: "80%" }}>
                      <EditText content={addNotes} setContent={setaddNotes} />
                    </div>
                  ) : null}
                </Grid>
              </TableContainer>
            </Grid>
          </FormControl>
          <Box className="form_btn">
            <Button
              className="btn"
              variant="outlined"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className="btn btn_primary"
              type="submit"
              onClick={(e) => {
                setError(true);
                if (imageArray?.length == 0) {
                  showError("Recipe image is required");
                  return;
                }
                // if(description=="")
                // {
                //   showError("Ingredients are required")
                //   return;
                // }
                if (recipeTime == "") {
                  showError("Preperation time is required");
                  return;
                }
                if (parseInt(recipeTime) > 999) {
                  showError("Preperation time can't exceed 999 minutes");
                  return;
                }
                if (currentRecipeId && currentRecipeName == "") {
                  showError("Recipe name is required");
                  return;
                }
                if (currentRecipeId && currentRecipeName?.length > 70) {
                  showError("Recipe name cant exceed 70 characters");
                  return;
                }
                if (serving == "") {
                  showError("Serving is required");
                  return;
                }
                // if(portionSize=="")
                //   {
                //     showError("Amount per serving is required");
                //     return;
                //   }
                if (parseInt(serving) > 50) {
                  showError("Serving should be less than 10000");
                }
                // if(directions=="")
                //   {
                //     showError("Directions are required");
                //   }
                // if(videoLink=="")
                // {
                //         showError("Video is required");
                //         return
                // }

                setNewRecipe("");

                setOpenModal(false);
                if (!currentRecipeId) {
                  setSnacks((prevData: any) => {
                    return [...prevData, { recipeName: newRecipe, type: type }];
                  });
                  handleAddRecipe();
                } else {
                  handleUpdateRecipe();
                }
                getRecipeByDietData();
                handleCloseModal();
                ////                            console.log("+++++++++++++++++");
                ////                            console.log(newRecipe);
                ////                            console.log(videoLink);
                ////                            console.log(directions);
                ////                            console.log(recipeTime);
                ////                            console.log(addNotes);
                ////                            console.log(description);
                ////                            console.log(nutritionData);
                ////                            console.log(selectedDiet);

                ////                            console.log("+++++++++++++++++");

                showToast("Recipe added successfully");
              }}
              // onClick={handleAddRecipe}
            >
              {currentRecipeId ? "Update" : "Add"}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <WarnModal
        setOpen={setOpen2}
        open={open2}
        name={"recipe"}
        handleDelete={() =>
          handleDelete(deleteRecipeById, selectedId, getRecipeByDietData)
        }
      />
      <WarnModal
        setOpen={setOpen3}
        open={open3}
        name={"nutrition"}
        handleDelete={() => handleDeleteRole(selectedId2)}
      />
    </Grid>
  );
};

export default Recipe;
