import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import MainContainer from "../../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { showError, showToast } from "../../../constants/toast";
import {
  useEditUserByIdMutation,
  useLazyGetUsersByIdQuery,
} from "../../../services/user";
import { UserResponse } from "../../../types/User";
import { UploadMedia } from "../../../utils/uploadMedia";
import { isString } from "../../../utils/validations";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import Loader from "../../../helpers/Loader";

const UsersForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [image, setImage] = useState<string>("");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [error, setError] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserResponse>();
  const [phoneDisplay, setPhoneDisplay] = useState("");
  const [getUserById]: any = useLazyGetUsersByIdQuery();
  const [editUser] = useEditUserByIdMutation();
  const [isLoading, setIsLoading] = useState(false);
  const getUserDetail = async (id: string) => {
    try {
      const response = await getUserById({
        id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setUserData(response?.data);
        setPhoneDisplay(response?.data?.countryCode + response?.data?.phone);
        setPhoneCode(response?.data?.countryCode || "+91");
        setImage(response?.data?.image || "");
        console.log(response.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userData?.email || "",
      fullName: userData?.fullName || "",
      phone: userData?.phone || "",
      // address:userData?.address||""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      fullName: Yup.string()
        .required("Full Name is required")
        .max(40, "Maximum 40 character are allowed")
        .min(2, "Minimum 2 characters are required"),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
      // address:Yup.string()
      // .required("Address is required")
      // .max(100,"Address shouldnt exceed 100 characters")
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      // if(!image)
      //   {
      //     showError("Image is required");
      //     return;
      //   }
      const body = {
        image: image,
        // email: formik.values.email,
        fullName: formik.values.fullName,
        // countryCode:phoneCode,
        // phone: formik.values.phone,
        // address:formik.values.address
        // appKey: new Date().toISOString(),
      };

      try {
        const encryptedData = generateEncryptedKeyBody(body);
        const response = await editUser({
          id: `${id}`,
          body: encryptedData,
        }).unwrap();
        showToast(response?.message || "User Updated Successfully");
        navigate("/manage-users");
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
    setPhoneDisplay(phone);
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    if (!file) {
      showError("Invalid file");
      return;
    }
    const allowedExtensions = ["png", "jpg", "jpeg"];
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    console.log("sssssssssssssssssssss", fileExtension);
    if (!allowedExtensions.includes(fileExtension || "")) {
      setIsLoading(false);
      showError("Invalid file format: only png, jpg images are allowed");
      return;
    }
    setIsLoading(true);
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (id) {
      getUserDetail(id);
    }
  }, []);

  return (
    <>
      <div className="main_loyout">
        <Loader isLoad={isLoading} />
        <div className="dashboard">
          <h1 className="mn_hdng"> Edit User Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-users");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className="custom_label">Image</Typography>
                  {image ? (
                    <div className="upload_image_preview">
                      <CardMedia component="img" image={image} alt="photo" />
                      <CancelIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={handleImageUpload}
                        />
                        <Button component="span" className="upload_image_btn">
                          <img
                            src={
                              image
                                ? image
                                : "/static/images/user_placeholder.png"
                            }
                            alt=""
                          />
                          <CameraAltIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                  {error && !image ? (
                    <h6 className="err_msg">This field is required</h6>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Full name</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    className="text_field"
                    name="fullName"
                    variant="outlined"
                    fullWidth
                    placeholder="Full name"
                    id="fullName"
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        if (val.target.value.length <= 40)
                          formik.handleChange(val);
                        else {
                          showError("Name cant exceed 40 characters");
                        }
                      }
                    }}
                    helperText={
                      formik.touched.fullName && formik.errors.fullName
                    }
                    inputProps={{ maxLength: 60 }}
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Email</Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"email"}
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Email"
                    id="email"
                    disabled
                    onMouseOver={(e) => {
                      //@ts-ignore
                      e.target.style.cursor = "not-allowed";
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Phone Number</Typography>
                  <PhoneInput
                    enableSearch={true}
                    value={phoneDisplay}
                    country={"us"}
                    inputClass="phoneInput"
                    inputStyle={{ width: "100%" }}
                    buttonClass="phoneBtn"
                    disabled={true}
                    placeholder="Phone Number"
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <h6 className="err_msg">
                      {formik.touched.phone && formik.errors.phone}
                    </h6>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </>
  );
};

export default UsersForm;
